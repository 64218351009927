<template>
  <div class="container p-3">

    <div v-if="loading" class="d-flex justify-content-center my-5">
      <Preloader/>
    </div>
    <div v-else class="my-3">
      <h4 class="text-center my-3">Успеваемость за академ разницу</h4>

      <!-- info section -->
      <div class="card my-3" v-if="Object.keys(studentInfos).length">
        <div class="card-body">
          <div class="card-title mb-4">
            <h5>Информация о студенте</h5>
          </div>

          <div>
            <p>
              <b>ФИО:</b> {{studentInfos?.last_name}} {{studentInfos?.first_name}} {{studentInfos?.middle_name}}
            </p>
            <p><b>Баркод:</b> {{ studentInfos?.barcode }}</p>
            <p>
              <b>Специальность или ОП:</b> {{ studentInfos?.educationProgram?.education_speciality_code }}
              {{ studentInfos?.educationProgram?.education_speciality_name }}
            </p>
            <p><b>Уровень обучения:</b> {{ studentInfos?.studyLevel?.name }}</p>
            <p><b>Форма обучения:</b> {{ studentInfos?.studyForm?.name }}</p>
            <p><b>Курс:</b> {{ studentInfos?.study_course }}</p>
            <p><b>Группа:</b> {{ studentInfos?.studentGroups?.name }}</p>
          </div>
        </div>

      </div>
      <!-- end info section -->

      <!-- list of courses -->
      <div v-if="differenceGrades.length">
        <DataTable :value="differenceGrades"
                   stripedRows responsiveLayout="scroll">
          <Column field="discipline_name" header="Название дисциплины"></Column>
          <Column header="Преподаватель">
            <template #body="{data}">
              {{data.pps_data_lastname}} {{data.pps_data_firstname}}
            </template>
          </Column>

          <Column field="semester" header="Семестр"></Column>
          <Column field="credit" header="Кредит"></Column>

          <Column field="vsk1" header="ВСК1"></Column>
          <Column field="vsk2" header="ВСК2"></Column>
          <Column field="exam" header="Экзамен"></Column>


          <Column field="score" header="Общий балл"></Column>
          <Column field="num_gpa" header="GPA"></Column>
          <Column field="letter_equiv" header="Букв. оценка"></Column>


        </DataTable>
      </div>
      <!-- end list of courses -->

      <div v-else>
        <div class="my-3 text-center">
          <h5>Академ разницы нет</h5>
        </div>
      </div>
    </div>

  </div>
</template>


<script>
import httpClient from "@/services/http.service"
import {getCookie} from "@/utils/helpers/cookies.helpers";

export default {
  name: "StudentDifference",
  data() {
    return {
      loading: true,
      barcode: +this.$route.query.barcode || 0,
      studentInfos: {},
      differenceGrades: [],
    }
  },
  methods: {
    async GET_STUDENT_DATA_BY_BARCODE() {
      try {
        const {status, data} = await httpClient.get(`student_data/student-data/get-info-by-barcode?barcode=${getCookie('USERNAME')}`)
        if (status === 200 && data.success) {
          this.studentInfos = data.success
        }
      } catch (e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      }
    },
    async GET_DIFFERENCE_GRADES_STUDENT() {
      try {
        const {status, data} = await httpClient.get(`requisites/difference-ratings/get-difference-grades-by-student`);
        if (status === 200) {
          this.differenceGrades = data
        }
      } catch (e) {
        this.$error({text: `Произошла ошибка ${JSON.stringify(e.response?.data?.errors)}`})
      }
    },
  },
  async mounted() {
    await this.GET_STUDENT_DATA_BY_BARCODE()
    await this.GET_DIFFERENCE_GRADES_STUDENT()
    this.loading = false
  }
}
</script>

<style scoped>

</style>